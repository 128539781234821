import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { getSlideWidth, getTrackLeft } from './sizes';
import Track from './track';
import styles from './mobile-carousel.scss';

/*
 Mobile carousel is inspired by https://github.com/akiran/react-slick/tree/master/src
*/

function getTrackCSS({ slideCount, slidesToShow, slideWidth, left, right }) {
  const trackWidth = (slideCount + 2 * slidesToShow) * slideWidth;

  const value = _.isNumber(left) ? left : right;

  return {
    opacity: 1,
    width: trackWidth,
    WebkitTransform: `translateX(${value}px)`,
    transform: `translateX(${value}px)`,
    transition: '',
    WebkitTransition: '',
    msTransform: `translateX(${value}px)`,
  };
}

function getTrackAnimateCSS(spec) {
  const style = getTrackCSS(spec);
  // useCSS is true by default so it can be undefined
  style.WebkitTransition = `-webkit-transform '${spec.speed}ms ${spec.cssEase}`;
  style.transition = `transform ${spec.speed}ms ${spec.cssEase}`;
  return style;
}

function calcSwipeDirection(touchObject) {
  const xDist = touchObject.startX - touchObject.curX;
  const yDist = touchObject.startY - touchObject.curY;
  const r = Math.atan2(yDist, xDist);

  let swipeAngle = Math.round((r * 180) / Math.PI);
  if (swipeAngle < 0) {
    swipeAngle = 360 - Math.abs(swipeAngle);
  }
  if (
    (swipeAngle <= 45 && swipeAngle >= 0) ||
    (swipeAngle <= 360 && swipeAngle >= 315)
  ) {
    return 'left';
  }
  if (swipeAngle >= 135 && swipeAngle <= 225) {
    return 'right';
  }

  return 'vertical';
}

export default class MobileCarousel extends React.Component {
  static propTypes = {
    width: PropTypes.number,
    totalCount: PropTypes.number,
    slidesToShow: PropTypes.number,
    afterChange: PropTypes.func,
    children: PropTypes.any,
    className: PropTypes.string,
    touchThreshold: PropTypes.number,
    slidesToScroll: PropTypes.number,
    initialSlide: PropTypes.number,
    slidesPreviewWidth: PropTypes.number,
    cssEase: PropTypes.string,
    edgeFriction: PropTypes.number,
    speed: PropTypes.number,
    getHeight: PropTypes.func,
    beforeChange: PropTypes.func,
    waitForAnimate: PropTypes.bool,
    trackClassName: PropTypes.string,
    onUserStartedTracking: PropTypes.func,
    isRTL: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    cssEase: 'ease',
    easing: 'linear',
    edgeFriction: 0.35,
    initialSlide: 0,
    slidesToScroll: 1,
    speed: 500,
    getHeight: _.constant(200),
    slidesToShow: 3,
    touchThreshold: 5,
    useCSS: true,
    waitForAnimate: true,
    afterChange: _.noop,
    beforeChange: _.noop,
    onUserStartedTracking: _.noop,
    slidesPreviewWidth: 0,
  };

  constructor(props) {
    super(props);

    this._currentSlide = props.initialSlide;
    this._touchObject = {
      startX: 0,
      startY: 0,
      curX: 0,
      curY: 0,
    };

    this.state = this.getMeasurements();
  }

  componentDidMount() {
    this.update();
    window.addEventListener('resize', this.update);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.initialSlide !== this.props.initialSlide ||
      nextProps.width !== this.props.width
    ) {
      this._currentSlide = nextProps.initialSlide;
      this.update(nextProps.width);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.update);
  }

  getMeasurements(nextWidth) {
    const { slidesToShow, slidesPreviewWidth } = this.props;
    const width = nextWidth ?? this.props.width;
    return {
      listWidth: width,
      trackWidth: width,
      slideWidth: getSlideWidth(width, slidesToShow, {
        slidesPreviewWidth,
      }),
    };
  }

  update = (nextWidth) => {
    if (!this.wrapper) {
      return;
    }

    const { slidesPreviewWidth, isRTL } = this.props;
    const measurements = this.getMeasurements(nextWidth);

    this.setState(measurements, () => {
      const targetLeft = this.getTrackLeft(
        this._currentSlide,
        measurements.slideWidth,
        slidesPreviewWidth,
      );

      const direction = isRTL ? 'right' : 'left';

      // getCSS function needs previously set state
      const trackStyle = getTrackCSS(
        _.assign(
          {
            [direction]: targetLeft,
            slideCount: this.getSlidesCount(),
          },
          this.props,
          this.state,
        ),
      );

      this.setTrackStyle(trackStyle);
    });
  };

  getTrackLeft(slideIndex, slideWidth, slidesPreviewWidth) {
    const { isRTL } = this.props;

    const trackLeft = getTrackLeft(
      this.getSlidesCount(),
      this.props.slidesToShow,
      slideIndex,
      slideWidth,
      slidesPreviewWidth,
    );

    return isRTL ? -trackLeft : trackLeft;
  }

  setTrackStyle(style) {
    _.assign(this.track.style, style);
  }

  swipeStart = (e) => {
    const posX = _.isUndefined(e.touches) ? e.clientX : e.touches[0].pageX;
    const posY = _.isUndefined(e.touches) ? e.clientY : e.touches[0].pageY;

    this._dragging = true;
    this._animating = false;

    this._touchObject = {
      startX: posX,
      startY: posY,
      curX: posX,
      curY: posY,
    };
  };

  getSlidesCount = () => React.Children.count(this.props.children);

  swipeMove = (e) => {
    if (!this._dragging) {
      return;
    }
    if (this._animating) {
      return;
    }

    const touchObject = _.clone(this._touchObject);
    const { slideWidth } = this.state;
    const {
      slidesToShow,
      slidesPreviewWidth,
      onUserStartedTracking,
      edgeFriction,
      isRTL,
    } = this.props;

    const currentSlide = this._currentSlide;

    const curLeft = this.getTrackLeft(
      currentSlide,
      slideWidth,
      slidesPreviewWidth,
    );
    touchObject.curX = e.touches ? e.touches[0].pageX : e.clientX;
    touchObject.curY = e.touches ? e.touches[0].pageY : e.clientY;
    touchObject.swipeLength = Math.round(
      Math.sqrt(Math.pow(touchObject.curX - touchObject.startX, 2)),
    );

    const positionOffset = touchObject.curX > touchObject.startX ? 1 : -1;

    const slidesCount = this.getSlidesCount();
    const swipeDirection = calcSwipeDirection.call(this, touchObject);
    let touchSwipeLength = touchObject.swipeLength;

    if (
      (currentSlide === 0 && swipeDirection === 'right') ||
      (currentSlide >= slidesCount - slidesToShow && swipeDirection === 'left')
    ) {
      touchSwipeLength = touchObject.swipeLength * edgeFriction;
    }

    const swipeLeft = curLeft + touchSwipeLength * positionOffset;
    this._touchObject = touchObject;

    const direction = isRTL ? 'right' : 'left';

    const trackStyle = getTrackCSS(
      _.assign(
        {
          [direction]: swipeLeft,
          slideCount: this.getSlidesCount(),
        },
        this.props,
        this.state,
      ),
    );

    this.setTrackStyle(trackStyle);

    if (touchObject.swipeLength > 4) {
      onUserStartedTracking();
    }
  };

  calcSlidesToScroll(swipeLength) {
    const { slideWidth, listWidth } = this.state;
    const { touchThreshold } = this.props;
    const minSwipe = listWidth / touchThreshold;
    let slidesCount = Math.floor(swipeLength / slideWidth);
    const lastSlideLength = swipeLength % slideWidth;
    if (lastSlideLength > minSwipe) {
      slidesCount += 1;
    }
    return slidesCount;
  }

  swipeEnd = (e) => {
    if (!this._dragging) {
      return;
    }

    const { touchThreshold, isRTL, slidesPreviewWidth } = this.props;

    const touchObject = this._touchObject;
    const minSwipe = this.state.listWidth / touchThreshold;
    const swipeDirection = calcSwipeDirection.call(this, touchObject);
    const currentSlide = this._currentSlide;

    // reset the state of touch related state variables.
    this._dragging = false;
    this._touchObject = {};
    // Fix for #13
    if (!touchObject.swipeLength) {
      return;
    }

    const needSwipeDirection = isRTL ? 'right' : 'left';
    const oppositeSwipeDirection = isRTL ? 'left' : 'right';

    if (touchObject.swipeLength > minSwipe) {
      const slidesToScroll = this.calcSlidesToScroll(touchObject.swipeLength);
      e.preventDefault();
      if (swipeDirection === needSwipeDirection) {
        this.slideHandler(currentSlide + slidesToScroll);
      } else if (swipeDirection === oppositeSwipeDirection) {
        this.slideHandler(currentSlide - slidesToScroll);
      } else {
        this.slideHandler(currentSlide);
      }
    } else {
      // Adjust the track back to it's original position.
      const currentLeft = this.getTrackLeft(
        currentSlide,
        this.state.slideWidth,
        slidesPreviewWidth,
      );

      this.setTrackStyle(
        getTrackAnimateCSS(
          _.assign(
            {
              [needSwipeDirection]: currentLeft,
              slideCount: this.getSlidesCount(),
            },
            this.props,
            this.state,
          ),
        ),
      );
    }
  };

  slideHandler(index) {
    // Functionality of animateSlide and postSlide is merged into this function
    const {
      waitForAnimate,
      slidesToShow,
      slidesPreviewWidth,
      beforeChange,
      isRTL,
    } = this.props;

    if (waitForAnimate && this._animating) {
      return;
    }

    let currentSlide;
    const slideCount = this.getSlidesCount();
    const targetSlide = index;

    if (targetSlide < 0) {
      currentSlide = 0;
    } else if (targetSlide > slideCount - slidesToShow) {
      currentSlide = slideCount - slidesToShow;
    } else {
      currentSlide = targetSlide;
    }

    const targetLeft = this.getTrackLeft(
      currentSlide,
      this.state.slideWidth,
      slidesPreviewWidth,
    );

    if (beforeChange) {
      beforeChange(this._currentSlide, currentSlide);
    }

    // Slide Transition happens here.
    // animated transition happens to target Slide and
    // non - animated transition happens to current Slide

    const direction = isRTL ? 'right' : 'left';

    const nextStateChanges = {
      trackStyle: getTrackCSS({
        [direction]: targetLeft,
        slideCount: this.getSlidesCount(),
        ...this.props,
        ...this.state,
      }),
      swipeLeft: null,
    };

    const callback = () => {
      this._animating = false;
      this.setTrackStyle(nextStateChanges.trackStyle);
      this.props.afterChange(currentSlide);
      this.track.removeEventListener('transitionend', callback);
    };

    this._animating = true;
    const trackStyle = getTrackAnimateCSS(
      _.assign(
        {
          [direction]: targetLeft,
          slideCount: this.getSlidesCount(),
        },
        this.props,
        this.state,
      ),
    );
    this.setTrackStyle(trackStyle);

    this._currentSlide = currentSlide;
    this.track.addEventListener('transitionend', callback);
  }

  changeSlide({ action, slideIndex }) {
    let slideOffset;
    let targetSlide;
    const slideCount = this.getSlidesCount();
    const currentSlide = this._currentSlide;
    const slidesToScroll = this.props.slidesToScroll;
    const unevenOffset = slideCount % slidesToScroll !== 0;
    const indexOffset = unevenOffset
      ? 0
      : (slideCount - currentSlide) % slidesToScroll;

    if (action === 'previous') {
      slideOffset =
        indexOffset === 0
          ? slidesToScroll
          : this.props.slidesToShow - indexOffset;
      targetSlide = currentSlide - slideOffset;
    } else if (action === 'next') {
      slideOffset = indexOffset === 0 ? slidesToScroll : indexOffset;
      targetSlide = currentSlide + slideOffset;
    } else if (action === 'index') {
      targetSlide = slideIndex;
      if (targetSlide === currentSlide) {
        return;
      }
    }

    this.slideHandler(targetSlide);
  }

  handlePrevButtonClick = () => {
    this.changeSlide({ action: 'previous' });
  };

  handleNextButtonClick = () => {
    this.changeSlide({ action: 'next' });
  };

  renderChildren() {
    const { slideWidth } = this.state;
    const { getHeight, children } = this.props;

    if (!slideWidth) {
      return null;
    }

    return React.Children.map(children, (elem) =>
      React.cloneElement(elem, {
        width: slideWidth,
        height: getHeight(elem, slideWidth),
      }),
    );
  }

  saveTrackRef = (node) => {
    this.track = node;
  };

  saveWrapperRef = (node) => {
    this.wrapper = node;
    this.update();
  };

  saveListRef = (node) => {
    this.list = node;
  };

  render() {
    const trackProps = {
      cssEase: this.props.cssEase,
      speed: this.props.speed,
      currentSlide: this._currentSlide,
      slideWidth: this.state.slideWidth,
      slidesToShow: this.props.slidesToShow,
      slideCount: this.getSlidesCount(),
      trackStyle: this.state.trackStyle,
    };

    const { className, trackClassName } = this.props;

    return (
      <div
        ref={this.saveWrapperRef}
        className={classnames(styles.container, className)}
      >
        <div
          ref={this.saveListRef}
          onMouseDown={this.swipeStart}
          onMouseMove={this.swipeMove}
          onMouseUp={this.swipeEnd}
          onMouseLeave={this.swipeEnd}
          onTouchStart={this.swipeStart}
          onTouchMove={this.swipeMove}
          onTouchEnd={this.swipeEnd}
          onTouchCancel={this.swipeEnd}
        >
          <Track
            getRef={this.saveTrackRef}
            className={trackClassName}
            {...trackProps}
          >
            {this.renderChildren()}
          </Track>
        </div>
      </div>
    );
  }
}
